import React, { useEffect, useRef, useState } from 'react'
import './plan.scss'
import Navbar from '../landing/header/Navbar'
import Joinus from '../Tech/Joinus/Joinus'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import Footer from '../landing/footer/Footer';
import Accordion from 'react-bootstrap/Accordion';
gsap.registerPlugin(ScrollTrigger);


const PlanNew = () => {
    const headingRef = useRef(null);

    useEffect(() => {
        const animateText = (ref, speed) => {
            const chars = ref.current.innerText.split('');
            ref.current.innerHTML = '';
            chars.forEach(char => {
                const charSpan = document.createElement('span');
                charSpan.innerText = char;
                ref.current.appendChild(charSpan);
            });
            gsap.from(ref.current.children, {
                y: 100,
                opacity: 0.2,
                stagger: 0.2,
                duration: speed
            });
            gsap.to(ref.current.children, {
                color: '#fff',
                stagger: 0.2,
                duration: speed
            });
        };

        animateText(headingRef, 0.2);
        // animateText(btn1Ref, 0.1);  
    }, []);
    // different examples of 
    // const headingRef1 = useRef(null);
    // const headingRef2 = useRef(null);
    const paragraphRef1 = useRef(null);
    // const paragraphRef2 = useRef(null);

    useEffect(() => {
        const animateText = (ref, speed, animationType) => {
            switch (animationType) {
                case 'fadeIn':
                    gsap.from(ref.current, {
                        opacity: 0,
                        duration: speed
                    });
                    break;
                case 'slideUp':
                    gsap.from(ref.current, {
                        y: 100,
                        opacity: 0,
                        duration: speed
                    });
                    break;
                case 'scale':
                    gsap.from(ref.current, {
                        scale: 0,
                        opacity: 0,
                        duration: speed
                    });
                    break;
                default:
                    break;
            }
        };

        // animateText(headingRef1, 0.5, 'fadeIn');
        // animateText(headingRef2, 1, 'slideUp');
        animateText(paragraphRef1, 2, 'scale');
        // animateText(paragraphRef2, 5, 'fadeIn');
    }, []);
    const firstSectionRef = useRef(null);
    const secondSectionRef = useRef(null);

    useEffect(() => {
        const isLargeScreen = window.matchMedia("(min-width: 1500px)").matches;
        const isMobileScreen = window.matchMedia("(max-width: 600px)").matches;

        gsap.to(firstSectionRef.current, {
            scrollTrigger: {
                trigger: secondSectionRef.current,
                start: isMobileScreen ? 'top 40%' : (isLargeScreen ? 'top 35%' : 'top 40%'),
                end: isMobileScreen ? 'bottom top' : (isLargeScreen ? 'bottom top' : 'bottom top'),
                scrub: true,
            },
            filter: 'blur(40px)',
        });
    }, []);


    // const textRefs1 = useRef([]);

    // useEffect(() => {
    //     const tl = gsap.timeline({
    //         scrollTrigger: {
    //             trigger: textRefs1.current,
    //             start: 'top center+=50',
    //             end: 'bottom center-=800',
    //             scrub: true,
    //         },
    //     });

    //     textRefs1.current.forEach((textRef, index) => {
    //         tl.from(textRef, { x: '-100%', opacity: 0, delay: index * 0.05 });
    //     });

    //     return () => {
    //         tl.kill();
    //     };
    // }, []);


    const textRefs1 = useRef([]);

    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: textRefs1.current,
                start: 'top center+=50',
                end: 'bottom center-=800',
                scrub: true,
                once: true,
            },
        });

        textRefs1.current.forEach((textRef, index) => {
            tl.from(textRef, { x: '-100%', opacity: 0, delay: index * 0.05 });
        });

        return () => {
            tl.kill();
        };
    }, []);



    const textRefs = useRef([]);
    const imageRefs = useRef([]);

    useEffect(() => {
        const tlText = gsap.timeline({
            scrollTrigger: {
                trigger: textRefs.current[0],
                start: 'top center+=50',
                end: 'bottom center-=200',
                scrub: 0.5,
                once: true, // Trigger only once
            },
        });

        textRefs.current.forEach((textRef, index) => {
            const imageRef = imageRefs.current[index];
            const isEven = (index + 1) % 2 === 0;

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: textRef,
                    start: 'top center+=50',
                    end: 'bottom center+=400',
                    scrub: 1,
                    once: true, // Trigger only once
                },
            });

            if (isEven) {
                tl.from(textRef, { x: '-100%', opacity: 0, duration: 1 });
            } else {
                tl.from(textRef, { x: '100%', opacity: 0, duration: 1 });
            }

            tl.to(imageRef, { opacity: 1, duration: 0.1, delay: isEven ? 0 : 0.1 * index });

            tl.timeScale(1);
        });

        return () => {
            tlText.kill();
            textRefs.current.forEach((textRef, index) => {
                const trigger = ScrollTrigger.getById(`textTrigger-${index}`);
                if (trigger) {
                    trigger.kill();
                }
            });
        };
    }, []);


    const [isHeightSmall, setIsHeightSmall] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsHeightSmall(window.innerWidth > 991 && window.innerHeight < 900);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <>
            <Navbar />
            <div className={isHeightSmall ? "planmain ifheightsmallsetreplan" : "planmain"}>
                <section className="techbanner" ref={firstSectionRef}>
                    <div className="custom-container">
                        <div className="techbannerinner">
                            <h1 className="techhead" ref={paragraphRef1}><span ref={headingRef}>THE plan</span></h1>
                        </div>
                    </div>
                </section>
                <section className="planinner" ref={secondSectionRef}>
                    {/* <div className="planinnerheader">
                        <p className="planinnerheaderpara">
                            <span>01.</span>
                            <span>the Plan</span>
                        </p>
                    </div> */}
                    <div className="plannew-section">
                        <div className="custom-container">
                            <Accordion defaultActiveKey="2">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><h6><span>Quarter 02</span> - completed</h6> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21ZM11.768 15.64L16.768 9.64L15.232 8.36L10.932 13.519L8.707 11.293L7.293 12.707L10.293 15.707L11.067 16.481L11.768 15.64Z" fill="#4BCF0D" />
                                    </svg></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="parent-cards">
                                            <div className="single-card">
                                                <img src="\assets\plan\plannew\testnetcompleted.svg" alt="img" className='img-fluid' />
                                                <div className="twice-text">
                                                    <h6><span>Testnet</span> - Completed</h6>
                                                    <img src="\assets\plan\plannew\completed-icon.svg" alt="img" className='img-fluid' />
                                                </div>
                                                <p>Launched on the Sepolia test network, the DOP testnet enabled users to test Phase 1 of our technology through hands-on interaction.</p>
                                            </div>
                                            <div className="single-card">
                                                <img src="\assets\plan\plannew\tokenclaimcompleted.svg" alt="img" className='img-fluid' />
                                                <div className="twice-text">
                                                    <h6><span>Token Claim</span> - Completed </h6>
                                                    <img src="\assets\plan\plannew\completed-icon.svg" alt="img" className='img-fluid' />
                                                </div>
                                                <p>Private token sale is concluded and DOP token is launched, allowing for broader community participation.</p>
                                            </div>
                                            <div className="single-card">
                                                <img src="\assets\plan\plannew\techcompleted.svg" alt="img" className='img-fluid' />
                                                <div className="twice-text">
                                                    <h6><span>Technology Development</span> - Completed</h6>
                                                    <img src="\assets\plan\plannew\completed-icon.svg" alt="img" className='img-fluid' />
                                                </div>
                                                <p>DOP will perform fully private transactions on Ethereum.</p>
                                            </div>
                                            <div className="single-card">
                                                <img src="\assets\plan\plannew\securitycompleted.svg" alt="img" className='img-fluid' />
                                                <div className="twice-text">
                                                    <h6><span>Security Audit</span> - Completed</h6>
                                                    <img src="\assets\plan\plannew\completed-icon.svg" alt="img" className='img-fluid' />
                                                </div>
                                                <p>A comprehensive security audit of DOP protocol by a leading auditing firm.</p>
                                            </div>
                                            <div className="single-card last-card">
                                                <img src="\assets\plan\plannew\mainnnetcompleted.svg" alt="img" className='img-fluid' />
                                                <div className="twice-text">
                                                    <h6><span>Mainnet Launch</span> - Completed</h6>
                                                    <img src="\assets\plan\plannew\completed-icon.svg" alt="img" className='img-fluid' />
                                                </div>
                                                <p>DOP migrates to the mainnet, letting users practice encrypting assets and transferring holdings within the protocol.</p>
                                            </div>
                                            {/* <div className="single-card">
                                                <img src="\assets\plan\plannew\exchangelisting.svg" alt="img" className='img-fluid' />
                                                <div className="twice-text">
                                                    <h6><span>Exchange Listing</span> - Completed</h6>
                                                    <img src="\assets\plan\plannew\completed-icon.svg" alt="img" className='img-fluid' />
                                                </div>
                                                <p>DOP tokens will be tradeable and transferrable on a tier1 CEX to offer the best possible liquidity.</p>
                                            </div> */}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><h6><span>Quarter 03</span> - completed</h6> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21ZM11.768 15.64L16.768 9.64L15.232 8.36L10.932 13.519L8.707 11.293L7.293 12.707L10.293 15.707L11.067 16.481L11.768 15.64Z" fill="#4BCF0D" />
                                    </svg></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="parent-cards">
                                            <div className="single-card">
                                                <img src="\assets\plan\plannew\devgrant.svg" alt="img" className='img-fluid' />
                                                <div className="twice-text">
                                                    <h6><span>Development Grant</span> - Completed</h6>
                                                    <img src="\assets\plan\plannew\completed-icon.svg" alt="img" className='img-fluid' />
                                                </div>
                                                <p>With the aim of expanding our ecosystem and enhancing our user experience, we have created a $5 million grant for funding various development initiatives that align with the DOP vision. Developers will be asked to submit project proposals for assessment.</p>
                                            </div>
                                            <div className="single-card">
                                                <img src="\assets\plan\plannew\bugbounty.svg" alt="img" className='img-fluid' />
                                                <div className="twice-text">
                                                    <h6><span>Bug Bounty Grant</span> - Completed</h6>
                                                    <img src="\assets\plan\plannew\completed-icon.svg" alt="img" className='img-fluid' />
                                                </div>
                                                <p>A $300K grant is being made available to reward Whitehat hackers who identify vulnerabilities in our protocol, contribute to the community and assist in ensuring our protocol is as flawless as possible.</p>
                                            </div>
                                            <div className="single-card">
                                                <img src="\assets\plan\plannew\nftsupport.svg" alt="img" className='img-fluid' />
                                                <div className="twice-text">
                                                    <h6><span>NFT Support</span> - Completed</h6>
                                                    <img src="\assets\plan\plannew\completed-icon.svg" alt="img" className='img-fluid' />
                                                </div>
                                                <p>This will allow users to securely manage NFTs by expanding functionality beyond ERC-20 tokens to include encryption and decryption of NFTs.</p>
                                            </div>
                                            {/* <div className="single-card">
                                                <img src="\assets\plan\plannew\sdklaunch.svg" alt="img" className='img-fluid' />
                                                    <h6><span>SDK Launch</span></h6>
                                                <p>The SDK (Software Development Kit) will empower developers to seamlessly integrate with DOP. This will enable the development of dApps that interact with our ecosystem directly.</p>
                                            </div> */}
                                            <div className="single-card">
                                                <img src="\assets\plan\plannew\devmajor2.svg" alt="img" className='img-fluid' />
                                                <div className="twice-text">
                                                    <h6><span>Deployment on 2 major EVMs</span> - Completed</h6>
                                                    <img src="\assets\plan\plannew\completed-icon.svg" alt="img" className='img-fluid' />
                                                </div>
                                                <p>Expected throughout Q3, 2024 our deployment into additional ecosystems, other than Ethereum, will reduce gas fees, and introduce our protocol to an even wider user base – EVMs. Specifications will be published closer to deployment.   </p>
                                            </div>
                                            <div className="single-card last-card">
                                                <img src="\assets\plan\plannew\daolaunch.svg" alt="img" className='img-fluid' />
                                                <div className="twice-text">
                                                    <h6><span>DAO Launch</span> - Completed</h6>
                                                    <img src="\assets\plan\plannew\completed-icon.svg" alt="img" className='img-fluid' />
                                                </div>
                                                <p>Through governance and decision-making processes, the DOP DAO will empower users to actively shape the future of the protocol, becoming another pillar of a strong and deeply engaged community.  </p>
                                            </div>
                                        </div>
                                        <div className="bottom-detail">
                                            <p>Please note: the sequence of the items listed in this quarterly plan does not reflect the order by which they are completed. </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><h6><span>Quarter 04</span> - in progress</h6> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21Z" fill="#EEAE0B" />
                                    </svg></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="parent-cards">
                                            {/* <div className="single-card">
                                                <img src="\assets\plan\plannew\crosschain.svg" alt="img" className='img-fluid' />
                                                <h6><span>Cross-chain ZK bridging</span></h6>
                                                <p>Wherever DOP is deployed users will be able to transfer and claim encrypted assets between all supported blockchains – as such, users will own their financial data.</p>
                                            </div> */}
                                            <div className="single-card">
                                                <img src="\assets\plan\plannew\selectivetrans.svg" alt="img" className='img-fluid' />
                                                <h6><span>Selective Transparency (DOP Scan)</span></h6>
                                                <p>Selective Transparency will offer users enhanced and customizable transparency options, allowing them to control and selectively share financial information. We've completed the R&D stage for our unique solution and comprehensive internal testing will commence in Q4. The official version is scheduled for release in 2025.</p>
                                            </div>
                                            {/* <div className="single-card">
                                                <img src="\assets\plan\plannew\devonmakor3.svg" alt="img" className='img-fluid' />
                                                <h6><span>Deployment on 3 major EVMs</span></h6>
                                                <p>Expanding the DOP ecosystem and bringing it to millions of new users, DOP will be deployed on three major EVM blockchains. </p>
                                            </div> */}
                                            {/* <div className="single-card">
                                                <img src="\assets\plan\plannew\mainnerairdrop.svg" alt="img" className='img-fluid' />
                                                <h6><span>Mainnet Airdrop (1/2)</span></h6>
                                                <p>Concluding the campaign that started when the DOP Mainnet was launched, rewarding all mainnet users according to their actions and contributions up until that point.</p>
                                            </div> */}
                                            <div className="single-card">
                                                <img src="\assets\plan\plannew\finalising.svg" alt="img" className='img-fluid' />
                                                <h6><span>Finalising Bitcoin.com integration</span></h6>
                                                <p>Announced last April at Token2049 in Dubai, this integration within the Bitcoin.com wallet will allow users to utilize DOP features within their preferred interface, bringing the DOP premise to millions of wallets.</p>
                                            </div>
                                            {/* <div className="single-card">
                                                <img src="\assets\plan\plannew\dopmainnerv1.svg" alt="img" className='img-fluid' />
                                                <h6><span>DOP Mainnet V1.1</span></h6>
                                                <p>An upgraded version of the Data Ownership Protocol after incorporating all the improvements and additions planned for Q3.</p>
                                            </div> */}
                                            {/* <div className="single-card">
                                                <img src="\assets\plan\plannew\sdkv2.svg" alt="img" className='img-fluid' />
                                                <h6><span>SDK V2</span></h6>
                                                <p>Incorporating the latest DOP advancements and features, the new version of the developer SDK will include enhanced tools for building on the DOP ecosystem.</p>
                                            </div> */}
                                            <div className="single-card last-card">
                                                <img src="\assets\plan\plannew\defi.svg" alt="img" className='img-fluid' />
                                                <h6><span>DeFi (First integration)</span></h6>
                                                <p>We've announced several DeFi integrations in Q3, which are currently in development and implementation stages. These integrations will enable additional actions such as lending, asset swapping, and NFT purchases to be performed on the protocol and directly through a DOP wallet. In Q4, a mobile version of DOP will be natively integrated into a new third-party wallet app, further enhancing accessibility and functionality for our users.</p>
                                            </div>
                                        </div>
                                        <div className="bottom-detail">
                                            <p>Please note: the sequence of the items listed in this quarterly plan does not reflect the order by which they are completed. </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </section>
            </div>
            <Joinus />
            <Footer />
        </>
    )
}

export default PlanNew