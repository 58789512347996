import React, { useEffect, useRef, useState } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { Modal } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";

gsap.registerPlugin(ScrollTrigger);

const ParagraphWithReadMore = ({ text, maxLength }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const truncatedText = isTruncated ? text.slice(0, maxLength) : text;

  return (
    <p>
      {truncatedText}
      {text.length > maxLength && (
        <span onClick={() => setIsTruncated(!isTruncated)}>
          {isTruncated ? 'Read More' : 'Read Less'}
        </span>
      )}
    </p>
  );
};

const Banner = ({ firstSectionRef, secondSectionRef }) => {
  const headingRef = useRef(null);
  // const btn1Ref = useRef(null);
  const btn2Ref = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed) => {
      const chars = ref.current.innerText.split("");

      ref.current.innerHTML = "";

      chars.forEach((char) => {
        const charSpan = document.createElement("span");
        charSpan.innerText = char;
        ref.current.appendChild(charSpan);
      });

      gsap.from(ref.current.children, {
        y: 100,
        opacity: 0.2,
        stagger: 0.1,
        duration: speed,
      });

      gsap.to(ref.current.children, {
        color: "#fff",
        stagger: 0.1,
        duration: speed,
      });
    };

    animateText(headingRef, 0.1);
    // animateText(btn1Ref, 0.1);
    animateText(btn2Ref, 0.1);
  }, []);

  // different examples of
  const headingRef1 = useRef(null);
  const headingRef2 = useRef(null);
  const paragraphRef1 = useRef(null);
  const paragraphRef2 = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed, animationType) => {
      switch (animationType) {
        case "fadeIn":
          gsap.from(ref.current, {
            opacity: 0,
            duration: speed,
          });
          break;
        case "slideUp":
          gsap.from(ref.current, {
            y: 100,
            opacity: 0,
            duration: speed,
          });
          break;
        case "scale":
          gsap.from(ref.current, {
            scale: 0,
            opacity: 0,
            duration: speed,
          });
          break;
        default:
          break;
      }
    };

    animateText(headingRef1, 0.5, "fadeIn");
    animateText(headingRef2, 1, "slideUp");
    animateText(paragraphRef1, 2, "scale");
    animateText(paragraphRef2, 5, "fadeIn");
  }, []);

  // const btnRef = useRef(null);

  // useEffect(() => {
  //   const animateText = (ref, speed) => {
  //     const chars = ref.current.innerText.split('');

  //     ref.current.innerHTML = '';
  //     chars.forEach(char => {
  //       const charSpan = document.createElement('span');
  //       charSpan.innerText = char;
  //       ref.current.appendChild(charSpan);
  //     });

  //     gsap.from(ref.current.children, {
  //       y: 100,
  //       opacity: 0.2,
  //       stagger: 0.03,
  //       duration: speed
  //     });

  //     gsap.to(ref.current.children, {
  //       color: '#fff',
  //       stagger: 0.03,
  //       duration: speed
  //     });
  //   };

  //   const btn = btnRef.current;

  //   btn.addEventListener('mouseenter', () => {
  //     animateText(btnRef, 0.2);
  //   });

  //   btn.addEventListener('mouseleave', () => {
  //     btnRef.current.innerHTML = btn.innerText;
  //   });

  //   return () => {
  //     btn.removeEventListener('mouseenter');
  //     btn.removeEventListener('mouseleave');
  //   };
  // }, []);

  const btnRef = useRef(null);

  useEffect(() => {
    const animateText = (ref, speed) => {
      const chars = ref.current.innerText.split("");

      ref.current.innerHTML = "";
      chars.forEach((char) => {
        const charSpan = document.createElement("span");
        charSpan.innerText = char;
        ref.current.appendChild(charSpan);
      });

      gsap.from(ref.current.children, {
        y: 100,
        opacity: 0.2,
        stagger: 0.03,
        duration: speed,
      });

      gsap.to(ref.current.children, {
        color: "#fff",
        stagger: 0.03,
        duration: speed,
      });
    };

    const btn = btnRef.current;

    const handleMouseEnter = () => {
      animateText(btnRef, 0.2);
    };

    const handleMouseLeave = () => {
      btnRef.current.innerHTML = btn.innerText;
    };

    btn.addEventListener("mouseenter", handleMouseEnter);
    btn.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      btn.removeEventListener("mouseenter", handleMouseEnter);
      btn.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);



  const longText = "Some cookies are required to make our website work and cannot be turned off. But we need your consent to use other cookies that are not essential. You can make your choices below and update your choice at any time in our cookie policy."

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  const handleDecline = () => {
    setIsVisible(false);
  };
  return (
    <>
      <section className="main-banner blurry-content" ref={firstSectionRef}>
        {/* <img src="\assets\landing\banner-model.png" alt="img" className="img-fluid banner-model" /> */}
        {/* <img src="\assets\landing\banner-bg.png" alt="img" className="img-fluid banner-bg" style={{ objectFit: 'cover' }} /> */}
        <img
          src="\assets\landing\banner-bg-mbl.png"
          alt="img"
          className="img-fluid banner-bg-mbl d-none"
        />
        <div className="custom-container" ref={secondSectionRef}>
          <div className="main-content">
            <h5 ref={paragraphRef1}>
              <span ref={headingRef}>DATA OWNERSHIP PROTOCOL</span>
            </h5>
            <div ref={headingRef2}>
              <p ref={paragraphRef2}>
                An innovative approach to data ownership that sets a new
                standard in blockchain technology.
              </p>
              <div className="twice-btns">
                <a href="https://app.dop.org/" target="_blank" className="btn-launch">
                  <h6 ref={btnRef}>Launch App</h6>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                  >
                    <path
                      d="M9.20831 6.16665L12.0416 8.99998L9.20831 11.8333M4.95831 6.16665L7.79165 8.99998L4.95831 11.8333"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <a
                  href="https://staking.dop.org/"
                  className="btn-transparent"
                  target="_blank"
                >
                  {" "}
                  <span ref={btn2Ref}>Stake DOP</span>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                  >
                    <path
                      d="M9.20834 6.16665L12.0417 8.99998L9.20834 11.8333M4.95834 6.16665L7.79168 8.99998L4.95834 11.8333"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        {isVisible && (
          <div className="cookies">
            <div className="cookiestext">
              <h4>Cookies consent</h4>
              <p>
                <ParagraphWithReadMore text={longText} maxLength={65} />
              </p>
              <div className="cookiesbutton">
                <button onClick={handleAccept} className="accept">Accept all</button>
                <button className="manage" onClick={handleShow1}>
                  Manage cookies
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
      <Offcanvas className="mblmenu" show={show1} onHide={handleClose1} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img src="\assets\arrow-left.png" alt="img" className="img-fluid" onClick={handleClose1} />{" "}
            Cookies consent
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="maintext">
            <h4>
              Some cookies are required to make our website work and cannot be
              turned off. But we need your consent to use other cookies that are
              not essential. You can make your choices below and update your
              choice at any time in our <span>cookie policy.</span>{" "}
            </h4>
          </div>
          <div className="brdr"></div>
          <div className="bottomparent">
            <div className="innerparent">
              <h6>Strictly necessary</h6>

              <div class="toggle-switch">
                <input class="toggle-input" id="toggle" type="checkbox" disabled />
                <label class="toggle-label disablelable" for="toggle"></label>
              </div>
            </div>
            <p>
              These cookies are needed to run our website, to keep it secure,
              and to obey regulations that apply on us.
            </p>
          </div>
          <div className="bottomparent">
            <div className="innerparent">
              <h6>Functional</h6>

              <div class="toggle-switch">
                <input class="toggle-input" id="toggle1" type="checkbox" disabled />
                <label class="toggle-label disablelable" for="toggle1"></label>
              </div>
            </div>
            <p>
              These cookies remember your region or country, language,
              accessibility options and your settings. This also includes things
              like your user name to be remembered you when you wish to log in
              to use our services.
            </p>
          </div>
          <div className="bottomparent">
            <div className="innerparent">
              <h6>Performance</h6>

              <div class="toggle-switch forblack">
                <input class="toggle-input" id="toggle2" type="checkbox" />
                <label class="toggle-label" for="toggle2"></label>
              </div>
            </div>
            <p>
              These cookies tell us how customers use our website. We study and
              profile this data to help us improve our products and
              performance..
            </p>
          </div>
          <div className="bottomparent">
            <div className="innerparent">
              <h6>Marketing</h6>

              <div class="toggle-switch forblack">
                <input class="toggle-input" id="toggle3" type="checkbox" />
                <label class="toggle-label" for="toggle3"></label>
              </div>
            </div>
            <p>
              These cookies help us decide which products, services and offers
              may be relevant for you. We may use this data to tailor the ads
              you see on our own website and other websites and apps.
            </p>
          </div>
          <div className="savebtnss">
            <button onClick={() => {
              handleAccept();
              handleClose1();
            }} className="save">Save preferences</button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        className="contact-modal iframemodal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Watch Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/KtLT0ZuPsxc?si=o5Yxk1dmQOC6sWB4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="youtubeiframe"
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Banner;
