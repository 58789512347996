import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Ecosystem = ({ switchToPage2, currentPage, setCurrentPage }) => {
    return (
        <>

            <section className="eco-section">
                <img src="\assets\roadmap\ecosystembg.png" alt="img" className='img-fluid protocolbg' />
                <div className="custom-container">
                    <div className="parent">
                        <div className="right-side">
                            <h5>The Vast Ecosystem</h5>
                            <p>Expanding DOP’s ecosystem with more applications and platforms leveraging the DOP protocol.
                            </p>
                            {
                                currentPage === 0 ? <a onClick={switchToPage2} className="btn-view">
                                    <h6 style={{ borderRight: "1px solid #000" }}>View</h6>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                        <path d="M9.20825 6.16671L12.0416 9.00004L9.20825 11.8334M4.95825 6.16671L7.79159 9.00004L4.95825 11.8334" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </a> : <a onClick={() => setCurrentPage(0)} className="btn-view">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                        <path d="M7.7915 6.16671L4.95817 9.00004L7.7915 11.8334M12.0415 6.16671L9.20817 9.00004L12.0415 11.8334" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <h6>Back</h6>

                                </a>
                            }


                        </div>
                        <div className="left-side">
                            <div className="main-cards">
                                <div className="single-card">
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\mobilewallet.svg" alt="img" className='img-fluid' />
                                        <h6>Mobile Wallet App</h6>
                                    </div>
                                    <p>A new third-party wallet app will feature native DOP integration, bringing our protocol to mobile users. This secure solution simplifies data management and DOP interactions, making our services more accessible on the go.</p>
                                </div>
                                <div className="single-card">
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\nft.svg" alt="img" className='img-fluid' />
                                        <h6>NFT Marketplace</h6>
                                    </div>
                                    <p>A secure platform for NFT transactions on our protocol. Enables creators to mint and sell digital assets, while collectors can buy and trade with confidence. Seamlessly incorporates DOP's privacy features for enhanced NFT ownership.</p>
                                </div>
                                <div className="single-card">
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\data.svg" alt="img" className='img-fluid' />
                                        <h6>Data Marketplace</h6>
                                    </div>
                                    <p>A secure platform for trading verified data. Users can monetize their information while buyers access trusted datasets. Leverages DOP's selective transparency features to ensure secure and controlled data sharing, creating new value streams for data owners.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ecosystem
