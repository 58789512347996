import { Link } from "react-router-dom";
import "./navbar.scss";
import { gsap } from 'gsap';
import { useEffect, useRef, useState } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Modal } from "react-bootstrap";


const Navbar = () => {
  const btnRef1 = useRef(null);
  const btnRef2 = useRef(null);
  const btnRef3 = useRef(null);
  const btnRef4 = useRef(null);
  const btnRef5 = useRef(null);
  const btnRef6 = useRef(null);
  const btnRef7 = useRef(null);
  const btnRef8 = useRef(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  useEffect(() => {
    const animateText = (ref, speed) => {
      const chars = ref.current.innerText.split('');

      ref.current.innerHTML = '';
      chars.forEach(char => {
        const charSpan = document.createElement('span');
        charSpan.innerText = char;
        ref.current.appendChild(charSpan);
      });

      gsap.from(ref.current.children, {
        y: 100,
        opacity: 0.2,
        stagger: 0.03,
        duration: speed
      });

      gsap.to(ref.current.children, {
        color: '#fff',
        stagger: 0.03,
        duration: speed
      });
    };

    const btn1 = btnRef1.current;
    const btn2 = btnRef2.current;
    const btn3 = btnRef3.current;
    const btn4 = btnRef4.current;
    const btn5 = btnRef5.current;
    const btn6 = btnRef6.current;
    const btn7 = btnRef7.current;
    // const btn8 = btnRef8.current;

    const mouseEnterHandler = () => {
      animateText(btnRef1, 0.2);
    };

    const mouseLeaveHandler = () => {
      btnRef1.current.innerHTML = btn1.innerText;
    };

    btn1.addEventListener('mouseenter', mouseEnterHandler);
    btn1.addEventListener('mouseleave', mouseLeaveHandler);

    const mouseEnterHandler2 = () => {
      animateText(btnRef2, 0.2);
    };

    const mouseLeaveHandler2 = () => {
      btnRef2.current.innerHTML = btn2.innerText;
    };

    btn2.addEventListener('mouseenter', mouseEnterHandler2);
    btn2.addEventListener('mouseleave', mouseLeaveHandler2);

    const mouseEnterHandler3 = () => {
      animateText(btnRef3, 0.2);
    };

    const mouseLeaveHandler3 = () => {
      btnRef3.current.innerHTML = btn3.innerText;
    };

    btn3.addEventListener('mouseenter', mouseEnterHandler3);
    btn3.addEventListener('mouseleave', mouseLeaveHandler3);


    const mouseEnterHandler4 = () => {
      animateText(btnRef4, 0.2);
    };

    const mouseLeaveHandler4 = () => {
      btnRef4.current.innerHTML = btn4.innerText;
    };

    btn4.addEventListener('mouseenter', mouseEnterHandler4);
    btn4.addEventListener('mouseleave', mouseLeaveHandler4);

    const mouseEnterHandler5 = () => {
      animateText(btnRef5, 0.2);
    };

    const mouseLeaveHandler5 = () => {
      btnRef5.current.innerHTML = btn5.innerText;
    };

    btn5.addEventListener('mouseenter', mouseEnterHandler5);
    btn5.addEventListener('mouseleave', mouseLeaveHandler5);



    const mouseEnterHandler6 = () => {
      animateText(btnRef6, 0.2);
    };

    const mouseLeaveHandler6 = () => {
      btnRef6.current.innerHTML = btn6.innerText;
    };

    btn6.addEventListener('mouseenter', mouseEnterHandler6);
    btn6.addEventListener('mouseleave', mouseLeaveHandler6);

    const mouseEnterHandler7 = () => {
      animateText(btnRef7, 0.2);
    };

    const mouseLeaveHandler7 = () => {
      btnRef7.current.innerHTML = btn7.innerText;
    };

    btn7.addEventListener('mouseenter', mouseEnterHandler7);
    btn7.addEventListener('mouseleave', mouseLeaveHandler7);

    // const mouseEnterHandler8 = () => {
    //   animateText(btnRef8, 0.2);
    // };

    // const mouseLeaveHandler8 = () => {
    //   btnRef8.current.innerHTML = btn8.innerText;
    // };

    // btn8.addEventListener('mouseenter', mouseEnterHandler8);
    // btn8.addEventListener('mouseleave', mouseLeaveHandler8);

    return () => {
      btn1.removeEventListener('mouseenter', mouseEnterHandler);
      btn1.removeEventListener('mouseleave', mouseLeaveHandler);
      btn2.removeEventListener('mouseenter', mouseEnterHandler2);
      btn2.removeEventListener('mouseleave', mouseLeaveHandler2);
      btn3.removeEventListener('mouseenter', mouseEnterHandler3);
      btn3.removeEventListener('mouseleave', mouseLeaveHandler3);
      btn4.removeEventListener('mouseenter', mouseEnterHandler4);
      btn4.removeEventListener('mouseleave', mouseLeaveHandler4);
      btn5.removeEventListener('mouseenter', mouseEnterHandler5);
      btn5.removeEventListener('mouseleave', mouseLeaveHandler5);
      btn6.removeEventListener('mouseenter', mouseEnterHandler6);
      btn6.removeEventListener('mouseleave', mouseLeaveHandler6);
      btn7.removeEventListener('mouseenter', mouseEnterHandler7);
      btn7.removeEventListener('mouseleave', mouseLeaveHandler7);
      // btn8.removeEventListener('mouseenter', mouseEnterHandler8);
      // btn8.removeEventListener('mouseleave', mouseLeaveHandler8);
    };
  }, []);
  return (
    <>
      <section className="main-navbar">
        <div className="custom-container">
          <nav class="navbar navbar-expand-xl">
            <Link class="navbar-brand" to="/"><img src="\logo.svg" alt="img" className="img-fluid" /></Link>
            <div className="twice-btnssssss">
              <a href="https://app.dop.org/" target="_blank" className="btn-launch d-none">
                <h6 ref={btnRef1}>Launch App</h6>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                  <path d="M9.20831 6.16671L12.0416 9.00004L9.20831 11.8334M4.95831 6.16671L7.79165 9.00004L4.95831 11.8334" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </a>
              <button class="navbar-toggler" type="button" onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M3 17H21M3 12H21M3 7H21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </button>
            </div>
            <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
              <ul class="navbar-nav">
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span ref={btnRef2}>About the protocol</span>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                      <path d="M11.8333 9.20837L9.00001 12.0417L6.16667 9.20837M11.8333 4.95837L9.00001 7.79171L6.16667 4.95837" stroke="#9A9A9A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                  <ul class="dropdown-menu">
                    <div className="parent-menu">
                      <li><Link class="dropdown-item" to="/company">The Company</Link></li>
                      <li><Link class="dropdown-item" to="/plan">The plan</Link></li>
                      <li><Link class="dropdown-item" to="/tech">The Tech</Link></li>
                      {/* <li><a class="dropdown-item" href="#">The Team</a></li> */}
                      <li><Link class="dropdown-item" to="/vision">The vision</Link></li>
                    </div>
                  </ul>

                </li>
                {/* <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span ref={btnRef8}>products</span>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                      <path d="M11.8333 9.20837L9.00001 12.0417L6.16667 9.20837M11.8333 4.95837L9.00001 7.79171L6.16667 4.95837" stroke="#9A9A9A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                  <ul class="dropdown-menu">
                    <div className="parent-menu">
                      <li><a class="dropdown-item" href="https://app.dop.org/" target="_blank"><img src="\assets\dopmainnet-icon.svg" alt="img" className="img-fluid" /></a></li>
                      <li><Link class="dropdown-item" to="/oracles"><img src="\assets\doporacle-icon.svg" alt="img" className="img-fluid" /></Link></li>
                      <li><a class="dropdown-item" href="https://tasks.market/" target="_blank"><img src="\assets\tasks-icon.svg" alt="img" className="img-fluid" /></a></li>
                    </div>
                  </ul>

                </li> */}
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span ref={btnRef7}>MARKETS</span>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                      <path d="M11.8333 9.20837L9.00001 12.0417L6.16667 9.20837M11.8333 4.95837L9.00001 7.79171L6.16667 4.95837" stroke="#9A9A9A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                  <ul class="dropdown-menu set-font-normal" style={{ maxHeight: "630px", minWidth: "260px" }}>
                    <div className="parent-menu">
                      <li><a href="https://bit.ly/3XShgMm" target="_blank" class="dropdown-item"><img src="\assets\markets\bybit.svg" alt="img" className="img-fluid me-2" />Bybit</a></li>
                      <li><a href="https://bit.ly/3XRNirI" target="_blank" class="dropdown-item"><img src="\assets\markets\gateio.svg" alt="img" className="img-fluid me-2" />Gate.io</a></li>
                      <li><a href="https://bit.ly/3LeuOu3" target="_blank" class="dropdown-item"><img src="\assets\markets\htx.svg" alt="img" className="img-fluid me-2" />HTX</a></li>
                      <li><a href="https://bit.ly/3LcTC5z" target="_blank" class="dropdown-item"><img src="\assets\markets\bitfinex.svg" alt="img" className="img-fluid me-2" />Bitfinex</a></li>
                      <li><a href="https://bit.ly/4cnSQi2" target="_blank" class="dropdown-item"><img src="\assets\markets\kucoin.svg" alt="img" className="img-fluid me-2" />KuCoin</a></li>
                      <li><a href="https://bit.ly/4eOFwoC" target="_blank" class="dropdown-item"><img src="\assets\markets\mexc.svg" alt="img" className="img-fluid me-2" />MEXC</a></li>
                      <li><a href="https://bit.ly/4cuFaBT" target="_blank" class="dropdown-item"><img src="\assets\markets\bingx.svg" alt="img" className="img-fluid me-2" />BingX</a></li>
                      <li><a href="https://bit.ly/4ctJPEj" target="_blank" class="dropdown-item"><img src="\assets\markets\whitebit.svg" alt="img" className="img-fluid me-2" />WhiteBIT</a></li>
                      {/* <li><a href="" class="dropdown-item"><img src="\assets\markets\bitget.svg" alt="img" className="img-fluid me-2" />Bitget</a></li> */}
                      {/* <li><a href="" class="dropdown-item"><img src="\assets\markets\uniswap.svg" alt="img" className="img-fluid me-2" />Uniswap</a></li> */}
                    </div>
                  </ul>

                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span ref={btnRef3}>Community</span>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                      <path d="M11.8333 9.20837L9.00001 12.0417L6.16667 9.20837M11.8333 4.95837L9.00001 7.79171L6.16667 4.95837" stroke="#9A9A9A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                  <ul class="dropdown-menu">
                    <div className="parent-menu">
                      <li><Link class="dropdown-item" to="/community">DOP's Community </Link></li>
                      <li><Link class="dropdown-item" to="/ambassador">Ambassador Program </Link></li>
                      <li><Link class="dropdown-item" to="/dopgrant">DOP Grant Program </Link></li>
                      <li><Link class="dropdown-item" to="/oracles">DOP Oracles </Link></li>

                    </div>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span ref={btnRef4}>Resources</span>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                      <path d="M11.8333 9.20837L9.00001 12.0417L6.16667 9.20837M11.8333 4.95837L9.00001 7.79171L6.16667 4.95837" stroke="#9A9A9A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                  <ul class="dropdown-menu">
                    <div className="parent-menu">
                      <li><Link class="dropdown-item" to="/hub">Knowledge hub </Link></li>
                      <li><Link class="dropdown-item" to="/blog">Blog</Link></li>
                      <li><Link class="dropdown-item" to="/dopcreator">DOP for creators</Link></li>
                    </div>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <Link class="nav-link dropdown-toggle" to="/contact" role="button">
                    <span ref={btnRef5}>Contact & Support</span>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                      <path d="M11.8333 9.20837L9.00001 12.0417L6.16667 9.20837M11.8333 4.95837L9.00001 7.79171L6.16667 4.95837" stroke="#9A9A9A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg> */}
                  </Link>
                  {/* <ul class="dropdown-menu">
                    <div className="parent-menu">
                      <li><a class="dropdown-item" href="#">FAQ</a></li>
                      <li><a class="dropdown-item" href="#">Support channels</a></li>
                      <li><a class="dropdown-item" href="#">Contact us</a></li>
                    </div>
                  </ul> */}
                </li>
                <li class="nav-item dropdown">
                  <a href="\DOP_Whitepaper_V2.1.pdf" target="_blank" class="nav-link dropdown-toggle" >
                    <span ref={btnRef6}>Whitepaper</span>
                  </a>
                </li>
              </ul>


              <div className="twice-btns">
                <a onClick={handleShow1} className="play-btn"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M9.5 15.584V8.41604C9.50015 8.32635 9.52442 8.23836 9.57026 8.16128C9.61611 8.0842 9.68184 8.02087 9.76057 7.97792C9.83931 7.93498 9.92814 7.914 10.0178 7.91719C10.1074 7.92037 10.1945 7.94761 10.27 7.99604L15.846 11.579C15.9166 11.6243 15.9747 11.6865 16.0149 11.7601C16.0552 11.8337 16.0763 11.9162 16.0763 12C16.0763 12.0839 16.0552 12.1664 16.0149 12.24C15.9747 12.3135 15.9166 12.3758 15.846 12.421L10.27 16.005C10.1945 16.0535 10.1074 16.0807 10.0178 16.0839C9.92814 16.0871 9.83931 16.0661 9.76057 16.0232C9.68184 15.9802 9.61611 15.9169 9.57026 15.8398C9.52442 15.7627 9.50015 15.6747 9.5 15.585V15.584Z" fill="white" />
                  <path d="M1 12C1 5.925 5.925 1 12 1C18.075 1 23 5.925 23 12C23 18.075 18.075 23 12 23C5.925 23 1 18.075 1 12ZM12 2.5C9.48044 2.5 7.06408 3.50089 5.28249 5.28249C3.50089 7.06408 2.5 9.48044 2.5 12C2.5 14.5196 3.50089 16.9359 5.28249 18.7175C7.06408 20.4991 9.48044 21.5 12 21.5C14.5196 21.5 16.9359 20.4991 18.7175 18.7175C20.4991 16.9359 21.5 14.5196 21.5 12C21.5 9.48044 20.4991 7.06408 18.7175 5.28249C16.9359 3.50089 14.5196 2.5 12 2.5Z" fill="white" />
                </svg></a>

                <a href="https://app.dop.org/" target="_blank" className="btn-launch">
                  <h6 ref={btnRef1}>Launch App</h6>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                    <path d="M9.20831 6.16671L12.0416 9.00004L9.20831 11.8334M4.95831 6.16671L7.79165 9.00004L4.95831 11.8334" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </a>
              </div>


            </div>
          </nav>
        </div>
      </section>


      <Offcanvas className="mobile-menu-offcanvas" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> <a href="/"><img src="\logo.svg" alt="img" className="img-fluid" /></a> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span ref={btnRef2}>About the protocol</span>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                  <path d="M11.8333 9.20837L9.00001 12.0417L6.16667 9.20837M11.8333 4.95837L9.00001 7.79171L6.16667 4.95837" stroke="#9A9A9A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </a>
              <ul class="dropdown-menu">
                <div className="parent-menu">
                  <li><Link class="dropdown-item" to="/company">The Company</Link></li>
                  <li><Link class="dropdown-item" to="/plan">The plan</Link></li>
                  <li><Link class="dropdown-item" to="/tech">The Tech</Link></li>
                  {/* <li><a class="dropdown-item" href="#">The Team</a></li> */}
                  <li><Link class="dropdown-item" to="/vision">The vision</Link></li>
                </div>
              </ul>
            </li>
            {/* <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>PRODUCTS</span>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                  <path d="M11.8333 9.20837L9.00001 12.0417L6.16667 9.20837M11.8333 4.95837L9.00001 7.79171L6.16667 4.95837" stroke="#9A9A9A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </a>
              <ul class="dropdown-menu market-menu set-font-normal">
                <div className="parent-menu">
                  <li><a class="dropdown-item" href="https://app.dop.org/" target="_blank"><img src="\assets\dopmainnet-icon.svg" alt="img" className="img-fluid" /></a></li>
                  <li><a class="dropdown-item" href="https://tasks.market/" target="_blank"><img src="\assets\tasks-icon.svg" alt="img" className="img-fluid" /></a></li>
                </div>
              </ul>

            </li> */}
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>MARKETS</span>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                  <path d="M11.8333 9.20837L9.00001 12.0417L6.16667 9.20837M11.8333 4.95837L9.00001 7.79171L6.16667 4.95837" stroke="#9A9A9A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </a>
              <ul class="dropdown-menu market-menu set-font-normal">
                <div className="parent-menu">
                  <li><a href="https://bit.ly/3XShgMm" target="_blank" class="dropdown-item"><img src="\assets\markets\bybit.svg" alt="img" className="img-fluid me-2" />Bybit</a></li>
                  <li><a href="https://bit.ly/3XRNirI" target="_blank" class="dropdown-item"><img src="\assets\markets\gateio.svg" alt="img" className="img-fluid me-2" />Gate.io</a></li>
                  <li><a href="https://bit.ly/3LeuOu3" target="_blank" class="dropdown-item"><img src="\assets\markets\htx.svg" alt="img" className="img-fluid me-2" />HTX</a></li>
                  <li><a href="https://bit.ly/3LcTC5z" target="_blank" class="dropdown-item"><img src="\assets\markets\bitfinex.svg" alt="img" className="img-fluid me-2" />Bitfinex</a></li>
                  <li><a href="https://bit.ly/4cnSQi2" target="_blank" class="dropdown-item"><img src="\assets\markets\kucoin.svg" alt="img" className="img-fluid me-2" />KuCoin</a></li>
                  <li><a href="https://bit.ly/4eOFwoC" target="_blank" class="dropdown-item"><img src="\assets\markets\mexc.svg" alt="img" className="img-fluid me-2" />MEXC</a></li>
                  <li><a href="https://bit.ly/4cuFaBT" target="_blank" class="dropdown-item"><img src="\assets\markets\bingx.svg" alt="img" className="img-fluid me-2" />BingX</a></li>
                  <li><a href="https://bit.ly/4ctJPEj" target="_blank" class="dropdown-item"><img src="\assets\markets\whitebit.svg" alt="img" className="img-fluid me-2" />WhiteBIT</a></li>
                  {/* <li><a href="" class="dropdown-item"><img src="\assets\markets\bitget.svg" alt="img" className="img-fluid me-2" />Bitget</a></li> */}
                  {/* <li><a href="" class="dropdown-item"><img src="\assets\markets\uniswap.svg" alt="img" className="img-fluid me-2" />Uniswap</a></li> */}
                </div>
              </ul>

            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span ref={btnRef3}>Community</span>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                  <path d="M11.8333 9.20837L9.00001 12.0417L6.16667 9.20837M11.8333 4.95837L9.00001 7.79171L6.16667 4.95837" stroke="#9A9A9A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </a>
              <ul class="dropdown-menu">
                <div className="parent-menu">
                  <li><Link class="dropdown-item" to="/community">DOP's Community </Link></li>
                  <li><Link class="dropdown-item" to="/ambassador">Ambassador Program </Link></li>
                  <li><Link class="dropdown-item" to="/dopgrant">DOP Grant Program </Link></li>
                  <li><Link class="dropdown-item" to="/oracles">DOP Oracles</Link></li>
                </div>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span ref={btnRef4}>Resources</span>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                  <path d="M11.8333 9.20837L9.00001 12.0417L6.16667 9.20837M11.8333 4.95837L9.00001 7.79171L6.16667 4.95837" stroke="#9A9A9A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </a>
              <ul class="dropdown-menu">
                <div className="parent-menu">
                  <li><Link class="dropdown-item" to="/hub">Knowledge hub </Link></li>
                  <li><Link class="dropdown-item" to="/blog">Blog</Link></li>
                  <li><Link class="dropdown-item" to="/dopcreator">DOP for creators</Link></li>
                </div>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <Link class="nav-link dropdown-toggle" to="/contact" role="button">
                <span ref={btnRef5}>Contact & Support</span>
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                      <path d="M11.8333 9.20837L9.00001 12.0417L6.16667 9.20837M11.8333 4.95837L9.00001 7.79171L6.16667 4.95837" stroke="#9A9A9A" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg> */}
              </Link>
              {/* <ul class="dropdown-menu">
                    <div className="parent-menu">
                      <li><a class="dropdown-item" href="#">FAQ</a></li>
                      <li><a class="dropdown-item" href="#">Support channels</a></li>
                      <li><a class="dropdown-item" href="#">Contact us</a></li>
                    </div>
                  </ul> */}
            </li>
            <li class="nav-item dropdown">
              <a href="\DOP_Whitepaper_V2.1.pdf" target="_blank" class="nav-link dropdown-toggle" >
                <span>Whitepaper</span>
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal className="contact-modal iframemodal" show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Watch Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <iframe
            width="560"
            height="315"
            src="https://youtu.be/ft1dxBIa-vM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="youtubeiframe"
          ></iframe> */}
          <iframe width="866" height="487" src="https://www.youtube.com/embed/ft1dxBIa-vM" title="Introduction to DOP" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen className="youtubeiframe"></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
