import React, { useEffect, useRef, useState } from 'react'
import "./roadmap.scss"
import Navbar from '../landing/header/Navbar'
import { Link } from 'react-router-dom';
import Protocol from './Protocol';
import Ecosystem from './Ecosystem';
import { Offcanvas } from 'react-bootstrap';

const Roadmap = () => {

    useEffect(() => {
        document.body.classList.add('roadmapopen-bodypaddingzero');


        return () => {
            document.body.classList.remove('roadmapopen-bodypaddingzero');
        };
    }, []);

    const [currentPage, setCurrentPage] = useState(0);

    const switchToPage1 = () => setCurrentPage(1);
    const switchToPage2 = () => setCurrentPage(2);

    // mobile Animation.apply............
    const [click, setClick] = useState(false);
    const [click1, setClick1] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [seeless, setSeeLess] = useState(true);


    const containerRef = useRef(null);
    const cardRefs = useRef([]);

    const logCardPositions = () => {
        if (containerRef.current) {
            const positions = cardRefs.current.map((card) =>
                card ? card.getBoundingClientRect().x : 0
            );
            // console.log("Card Positions:", positions);
            if (positions[4] == -1584.800048828125) {
                setSeeLess(false);
            } else {
                setSeeLess(true);
            }
        }
    };

    useEffect(() => {
        // Log initial card positions on mount
        logCardPositions();
    }, []);

    const handleScroll = () => {
        // Log updated card positions on scroll
        logCardPositions();
    };

    return (
        <>
            <div style={{overflow: "hidden"}}>
            <div className={click ? "wrapper-roadmap wrapper-roadmapactive" : click1 ? "wrapper-roadmap wrapper-roadmapactive1" : "wrapper-roadmap"}>
                <Navbar />
                <section className="roadmap-section desktop-roadmap">
                    <div className={currentPage === 1 ? "containerrr activepage2" : currentPage === 2 ? "containerrr activepage1" : "containerrr"}>
                        <div className={`pagee pagee1 ${currentPage === 1 ? 'activee' : ''}`}>
                            <Protocol switchToPage1={switchToPage1} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                        </div>

                        <div className={`pagee pagee2 ${currentPage === 2 ? 'activee' : ''}`}>
                            <Ecosystem switchToPage2={switchToPage2} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                        </div>
                    </div>
                </section>
                <section className="mobile-roadmap d-none">
                    <div className="parent">
                        <div className="upper-side">
                            <img src="\assets\roadmap\mobile-blackimg.png" alt="img" className='img-fluid mobile-blackimg' />
                            <div className="main-content">
                                <h5>The Protocol</h5>
                                <p>The backbone of DOP, enabling secure data sharing with selective transparency.
                                </p>
                                <a onClick={() => {
                                    setClick(true);
                                    handleShow(true);
                                }} className="btn-view">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                        <path d="M7.79175 6.16671L4.95841 9.00004L7.79175 11.8334M12.0417 6.16671L9.20841 9.00004L12.0417 11.8334" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <h6>View</h6>
                                </a>

                            </div>
                        </div>
                        <div className="bottom-side">
                            <img src="\assets\roadmap\mobile-whitebg.png" alt="img" className='img-fluid mobile-whitebg' />
                            <div className="main-content">
                                <h5>The Vast Ecosystem</h5>
                                <p>Expanding DOP’s ecosystem with more applications and platforms leveraging the DOP protocol.
                                </p>
                                <a onClick={() => {
                                    setClick1(true);
                                    handleShow3(true);
                                }} className="btn-view">
                                    <h6 style={{ borderRight: "1px solid #000" }}>View</h6>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                        <path d="M9.20825 6.16671L12.0416 9.00004L9.20825 11.8334M4.95825 6.16671L7.79159 9.00004L4.95825 11.8334" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </a>

                            </div>
                        </div>
                    </div>
                </section>

            </div>
            </div>


            <Offcanvas placement='end' className="mobile-menu-offcanvas roadmap-offcanvas" show={show} onHide={handleClose} backdrop={false}>
                <Offcanvas.Header>
                    <Navbar />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <img src="\assets\roadmap\offcanvas-blackbg.png" alt="img" className='img-fluid offcanvas-blackbg' />
                    <div className="upper-heading">
                        <h4>The Protocol</h4>
                        <p>The backbone of DOP, enabling secure data sharing with selective transparency.
                        </p>
                    </div>
                    <div className="bottom-cards">
                        <div className="main-cards" style={{ padding: "0 20px" }} ref={containerRef} onScroll={handleScroll}>
                            <div className="single-card" ref={(el) => (cardRefs.current[0] = el)}>
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\sdk.svg" alt="img" className='img-fluid' />
                                    <h6>SDK</h6>
                                </div>
                                <p>The SDK (Software Development Kit) will provide developers with the tools to seamlessly integrate DOP functionalities into other apps. This will enable the development of dApps that interact with our ecosystem directly.</p>
                            </div>
                            <div className="single-card" ref={(el) => (cardRefs.current[1] = el)}>
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\deployment.svg" alt="img" className='img-fluid' />
                                    <h6>Deployment on Additional Networks</h6>
                                </div>
                                <p>Expanding DOP's compatibility across various blockchain networks to enhance accessibility and interoperability. This strategic expansion will broaden our ecosystem's reach and utility.</p>
                            </div>
                            <div className="single-card" ref={(el) => (cardRefs.current[2] = el)}>
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\selectivetransparency.svg" alt="img" className='img-fluid' />
                                    <h6>Selective Transparency</h6>
                                </div>
                                <p>Empowering users with customizable data-sharing controls, balancing transparency with privacy. This feature allows selective disclosure of financial information, building trust while preserving confidentiality.</p>
                            </div>
                            <div className="single-card" ref={(el) => (cardRefs.current[3] = el)}>
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\protocolv1.svg" alt="img" className='img-fluid' />
                                    <h6>Protocol V1</h6>
                                </div>
                                <p>The first official full version, moving beyond beta to set the foundation for DOP's core services. Introduces Selective Transparency features and fully customizable user profiles on DOP Explorer, advancing our vision of user-controlled data.</p>
                            </div>
                            <div className="single-card" ref={(el) => (cardRefs.current[4] = el)} style={!seeless ? { background: "#0B0B0B" } : {}}>
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\transioning.svg" alt="img" className='img-fluid' />
                                    <h6>Transitioning to Decentralization</h6>
                                </div>
                                <p>Moving towards a fully decentralized governance structure. <br /> Enhances community-driven decision-making.</p>
                                <a className="btn-view" onClick={() => setSeeLess(!seeless)}>
                                    <h6 style={!seeless ? {background: "#0B0B0B"} : {}}>{seeless ? "Read More" : "Read Less"}</h6>
                                    {
                                        !seeless ? <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                        <path d="M5.66634 8.29167L8.49968 5.45834L11.333 8.29167M5.66634 12.5417L8.49967 9.70834L11.333 12.5417" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg> :  <svg style={{transform: "rotate(180deg)"}} xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                        <path d="M5.66634 8.29167L8.49968 5.45834L11.333 8.29167M5.66634 12.5417L8.49967 9.70834L11.333 12.5417" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    }
                                    
                                   
                                </a>



                            </div>
                        </div>
                        {
                            !seeless && <div className="main-cards" style={{ padding: "0 20px", paddingLeft: "20px", paddingBottom: "50px", marginBottom: "50px" }}>
                                <div className="single-card" style={{background: "#0B0B0B"}}>
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\github.svg" alt="img" className='img-fluid' />
                                        <h6>Public Github</h6>
                                    </div>
                                    <p>DOP’s Github will be made public, with access permissions granted to the DOP Foundation</p>
                                </div>
                                <div className="single-card" style={{background: "#0B0B0B"}}>
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\establish.svg" alt="img" className='img-fluid' />
                                        <h6>Establishing the DOP Foundation:</h6>
                                    </div>
                                    <p>Built from the core team members, the DOP Foundation will operate in line with the community’s goals as decided through the DAO.</p>
                                </div>
                                <div className="single-card" style={{background: "#0B0B0B"}}>
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\smart.svg" alt="img" className='img-fluid' />
                                        <h6>Smart Contract-Based DAO</h6>
                                    </div>
                                    <p>Launching an on-chain decentralized organization that will manage the protocol. This DAO will operate transparently through smart contracts.</p>
                                </div>
                                <div className="single-card" style={{background: "#0B0B0B"}}>
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\deleting.svg" alt="img" className='img-fluid' />
                                        <h6>Deleting Protocol Keys (Github)</h6>
                                    </div>
                                    <p>Once deleted, it will no longer be possible to change the protocol’s code repositories (not even by the core team), thus completely removing central authority.</p>
                                </div>
                            </div>
                        }

                    </div>
                    <button onClick={() => {
                        handleShow1();
                        setSeeLess(true);
                    }} className="bottom-abs-btn">
                        The Vast Ecosystem <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                            <path d="M8.83366 11.4583L13.0003 7.29165L17.167 11.4583M8.83366 17.7083L13.0003 13.5416L17.167 17.7083" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                </Offcanvas.Body>
            </Offcanvas>


            <Offcanvas placement='bottom' className="mobile-menu-offcanvas roadmap-offcanvas2" show={show1} onHide={handleClose1} backdrop={false}>
                <Offcanvas.Header>
                    <Navbar />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <img src="\assets\roadmap\offcanvas-whitebg.png" alt="img" className='img-fluid offcanvas-blackbg' />
                    <div className="upper-heading">
                        <h4>The Vast Ecosystem</h4>
                        <p>Expanding DOP’s ecosystem with more applications and platforms leveraging the DOP protocol.
                        </p>
                    </div>
                    <div className="bottom-cards">
                        <div className="main-cards" style={{ padding: "0 20px" }}>
                            <div className="single-card">
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\mobilewallet.svg" alt="img" className='img-fluid' />
                                    <h6>Mobile Wallet App</h6>
                                </div>
                                <p>A new third-party wallet app will feature native DOP integration, bringing our protocol to mobile users. This secure solution simplifies data management and DOP interactions, making our services more accessible on the go.</p>
                            </div>
                            <div className="single-card">
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\nft.svg" alt="img" className='img-fluid' />
                                    <h6>NFT Marketplace</h6>
                                </div>
                                <p>A secure platform for NFT transactions on our protocol. Enables creators to mint and sell digital assets, while collectors can buy and trade with confidence. Seamlessly incorporates DOP's privacy features for enhanced NFT ownership.</p>
                            </div>
                            <div className="single-card">
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\data.svg" alt="img" className='img-fluid' />
                                    <h6>Data Marketplace</h6>
                                </div>
                                <p>A secure platform for trading verified data. Users can monetize their information while buyers access trusted datasets. Leverages DOP's selective transparency features to ensure secure and controlled data sharing, creating new value streams for data owners.</p>
                            </div>

                        </div>
                    </div>
                    <button onClick={() => {
                        handleShow2();  
                        setTimeout(handleClose1, 1000);  
                        setSeeLess(true);
                    }} className="bottom-abs-btn">
                        The Protocol <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                            <path d="M8.83366 13.5416L13.0003 17.7083L17.167 13.5416M8.83366 7.29163L13.0003 11.4583L17.167 7.29163" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas placement='top' className="mobile-menu-offcanvas roadmap-offcanvas" show={show2} onHide={handleClose2} backdrop={false}>
                <Offcanvas.Header>
                    <Navbar />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <img src="\assets\roadmap\offcanvas-blackbg.png" alt="img" className='img-fluid offcanvas-blackbg' />
                    <div className="upper-heading">
                        <h4>The Protocol</h4>
                        <p>The backbone of DOP, enabling secure data sharing with selective transparency.
                        </p>
                    </div>
                    <div className="bottom-cards">
                        <div className="main-cards" style={{ padding: "0 20px" }} ref={containerRef} onScroll={handleScroll}>
                            <div className="single-card" ref={(el) => (cardRefs.current[0] = el)}>
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\sdk.svg" alt="img" className='img-fluid' />
                                    <h6>SDK</h6>
                                </div>
                                <p>The SDK (Software Development Kit) will provide developers with the tools to seamlessly integrate DOP functionalities into other apps. This will enable the development of dApps that interact with our ecosystem directly.</p>
                            </div>
                            <div className="single-card" ref={(el) => (cardRefs.current[1] = el)}>
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\deployment.svg" alt="img" className='img-fluid' />
                                    <h6>Deployment on Additional Networks</h6>
                                </div>
                                <p>Expanding DOP's compatibility across various blockchain networks to enhance accessibility and interoperability. This strategic expansion will broaden our ecosystem's reach and utility.</p>
                            </div>
                            <div className="single-card" ref={(el) => (cardRefs.current[2] = el)}>
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\selectivetransparency.svg" alt="img" className='img-fluid' />
                                    <h6>Selective Transparency</h6>
                                </div>
                                <p>Empowering users with customizable data-sharing controls, balancing transparency with privacy. This feature allows selective disclosure of financial information, building trust while preserving confidentiality.</p>
                            </div>
                            <div className="single-card" ref={(el) => (cardRefs.current[3] = el)}>
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\protocolv1.svg" alt="img" className='img-fluid' />
                                    <h6>Protocol V1</h6>
                                </div>
                                <p>The first official full version, moving beyond beta to set the foundation for DOP's core services. Introduces Selective Transparency features and fully customizable user profiles on DOP Explorer, advancing our vision of user-controlled data.</p>
                            </div>
                            <div className="single-card" ref={(el) => (cardRefs.current[4] = el)} style={!seeless ? { background: "#0B0B0B" } : {}}>
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\transioning.svg" alt="img" className='img-fluid' />
                                    <h6>Transitioning to Decentralization</h6>
                                </div>
                                <p>Moving towards a fully decentralized governance structure. <br /> Enhances community-driven decision-making.</p>
                                <a className="btn-view" onClick={() => setSeeLess(!seeless)}>
                                    <h6 style={!seeless ? {background: "#0B0B0B"} : {}}>{seeless ? "Read More" : "Read Less"}</h6>
                                    {
                                        !seeless ? <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                        <path d="M5.66634 8.29167L8.49968 5.45834L11.333 8.29167M5.66634 12.5417L8.49967 9.70834L11.333 12.5417" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg> :  <svg style={{transform: "rotate(180deg)"}} xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                        <path d="M5.66634 8.29167L8.49968 5.45834L11.333 8.29167M5.66634 12.5417L8.49967 9.70834L11.333 12.5417" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    }
                                </a>



                            </div>
                        </div>
                        {
                            !seeless && <div className="main-cards" style={{ padding: "0 20px", paddingLeft: "20px", paddingBottom: "50px", marginBottom: "50px" }}>
                                <div className="single-card" style={{background: "#0B0B0B"}}>
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\github.svg" alt="img" className='img-fluid' />
                                        <h6>Public Github</h6>
                                    </div>
                                    <p>DOP’s Github will be made public, with access permissions granted to the DOP Foundation</p>
                                </div>
                                <div className="single-card" style={{background: "#0B0B0B"}}>
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\establish.svg" alt="img" className='img-fluid' />
                                        <h6>Establishing the DOP Foundation:</h6>
                                    </div>
                                    <p>Built from the core team members, the DOP Foundation will operate in line with the community’s goals as decided through the DAO.</p>
                                </div>
                                <div className="single-card" style={{background: "#0B0B0B"}}>
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\smart.svg" alt="img" className='img-fluid' />
                                        <h6>Smart Contract-Based DAO</h6>
                                    </div>
                                    <p>Launching an on-chain decentralized organization that will manage the protocol. This DAO will operate transparently through smart contracts.</p>
                                </div>
                                <div className="single-card" style={{background: "#0B0B0B"}}>
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\deleting.svg" alt="img" className='img-fluid' />
                                        <h6>Deleting Protocol Keys (Github)</h6>
                                    </div>
                                    <p>Once deleted, it will no longer be possible to change the protocol’s code repositories (not even by the core team), thus completely removing central authority.</p>
                                </div>
                            </div>
                        }

                    </div>
                    <button onClick={() => {
                        handleShow1();  
                        setTimeout(handleClose2, 1000);  
                        setSeeLess(true);
                    }} className="bottom-abs-btn">
                        The Vast Ecosystem <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                            <path d="M8.83366 11.4583L13.0003 7.29165L17.167 11.4583M8.83366 17.7083L13.0003 13.5416L17.167 17.7083" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas placement='start' className="mobile-menu-offcanvas roadmap-offcanvas2" show={show3} onHide={handleClose3} backdrop={false}>
                <Offcanvas.Header>
                    <Navbar />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <img src="\assets\roadmap\offcanvas-whitebg.png" alt="img" className='img-fluid offcanvas-blackbg' />
                    <div className="upper-heading">
                        <h4>The Vast Ecosystem</h4>
                        <p>Expanding DOP’s ecosystem with more applications and platforms leveraging the DOP protocol.
                        </p>
                    </div>
                    <div className="bottom-cards">
                        <div className="main-cards" style={{ padding: "0 20px" }}>
                            <div className="single-card">
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\mobilewallet.svg" alt="img" className='img-fluid' />
                                    <h6>Mobile Wallet App</h6>
                                </div>
                                <p>A new third-party wallet app will feature native DOP integration, bringing our protocol to mobile users. This secure solution simplifies data management and DOP interactions, making our services more accessible on the go.</p>
                            </div>
                            <div className="single-card">
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\nft.svg" alt="img" className='img-fluid' />
                                    <h6>NFT Marketplace</h6>
                                </div>
                                <p>A secure platform for NFT transactions on our protocol. Enables creators to mint and sell digital assets, while collectors can buy and trade with confidence. Seamlessly incorporates DOP's privacy features for enhanced NFT ownership.</p>
                            </div>
                            <div className="single-card">
                                <div className="top-area">
                                    <img src="\assets\roadmap\icons\data.svg" alt="img" className='img-fluid' />
                                    <h6>Data Marketplace</h6>
                                </div>
                                <p>A secure platform for trading verified data. Users can monetize their information while buyers access trusted datasets. Leverages DOP's selective transparency features to ensure secure and controlled data sharing, creating new value streams for data owners.</p>
                            </div>

                        </div>
                    </div>
                    <button onClick={() => {
                        handleShow2();  
                        setTimeout(handleClose1, 1000);  
                        setSeeLess(true);
                    }} className="bottom-abs-btn">
                        The Protocol <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                            <path d="M8.83366 13.5416L13.0003 17.7083L17.167 13.5416M8.83366 7.29163L13.0003 11.4583L17.167 7.29163" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default Roadmap
