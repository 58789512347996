import React, { useState } from 'react'
import Navbar from '../landing/header/Navbar'
import { Link } from 'react-router-dom';

const Protocol = ({ switchToPage1, currentPage, setCurrentPage }) => {
    const [seeless, setSeeLess] = useState(true);
    return (
        <>
            <section className="protocol-section">
                <img src="\assets\roadmap\protocolbg.png" alt="img" className='img-fluid protocolbg' />
                <div className="custom-container">
                    <div className="parent">
                        <div className="left-side">
                            <div className="main-cards">
                                <div className="single-card">
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\sdk.svg" alt="img" className='img-fluid' />
                                        <h6>SDK</h6>
                                    </div>
                                    <p>The SDK (Software Development Kit) will provide developers with the tools to seamlessly integrate DOP functionalities into other apps. This will enable the development of dApps that interact with our ecosystem directly.</p>
                                </div>
                                <div className="single-card">
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\deployment.svg" alt="img" className='img-fluid' />
                                        <h6>Deployment on Additional Networks</h6>
                                    </div>
                                    <p>Expanding DOP's compatibility across various blockchain networks to enhance accessibility and interoperability. This strategic expansion will broaden our ecosystem's reach and utility.</p>
                                </div>
                                <div className="single-card">
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\selectivetransparency.svg" alt="img" className='img-fluid' />
                                        <h6>Selective Transparency</h6>
                                    </div>
                                    <p>Empowering users with customizable data-sharing controls, balancing transparency with privacy. This feature allows selective disclosure of financial information, building trust while preserving confidentiality.</p>
                                </div>
                                <div className="single-card">
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\protocolv1.svg" alt="img" className='img-fluid' />
                                        <h6>Protocol V1</h6>
                                    </div>
                                    <p>The first official full version, moving beyond beta to set the foundation for DOP's core services. Introduces Selective Transparency features and fully customizable user profiles on DOP Explorer, advancing our vision of user-controlled data.</p>
                                </div>
                                <div className="single-card special-card">
                                    <div className="top-area">
                                        <img src="\assets\roadmap\icons\transioning.svg" alt="img" className='img-fluid' />
                                        <h6>Transitioning to Decentralization</h6>
                                    </div>
                                    <p>Moving towards a fully decentralized governance structure. <br /> Enhances community-driven decision-making.</p>
                                    <a className="btn-view" onClick={() => setSeeLess(!seeless)}>
                                        <h6>{seeless ? "Read More" : "Read Less"}</h6>
                                        {
                                            !seeless ? <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                                <path d="M5.66634 8.29167L8.49968 5.45834L11.333 8.29167M5.66634 12.5417L8.49967 9.70834L11.333 12.5417" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg> : <svg style={{ transform: "rotate(180deg)" }} xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                                <path d="M5.66634 8.29167L8.49968 5.45834L11.333 8.29167M5.66634 12.5417L8.49967 9.70834L11.333 12.5417" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        }


                                    </a>
                                    {
                                        !seeless ? <div className="inside-cards">
                                            <div className="single-card">
                                                <div className="top-area">
                                                    <img src="\assets\roadmap\icons\github.svg" alt="img" className='img-fluid' />
                                                    <h6>Public Github</h6>
                                                </div>
                                                <p>DOP’s Github will be made public, with access permissions granted to the DOP Foundation</p>
                                            </div>
                                            <div className="single-card">
                                                <div className="top-area">
                                                    <img src="\assets\roadmap\icons\establish.svg" alt="img" className='img-fluid' />
                                                    <h6>Establishing the DOP Foundation:</h6>
                                                </div>
                                                <p>Built from the core team members, the DOP Foundation will operate in line with the community’s goals as decided through the DAO.</p>
                                            </div>
                                            <div className="single-card">
                                                <div className="top-area">
                                                    <img src="\assets\roadmap\icons\smart.svg" alt="img" className='img-fluid' />
                                                    <h6>Smart Contract-Based DAO</h6>
                                                </div>
                                                <p>Launching an on-chain decentralized organization that will manage the protocol. This DAO will operate transparently through smart contracts.</p>
                                            </div>
                                            <div className="single-card">
                                                <div className="top-area">
                                                    <img src="\assets\roadmap\icons\deleting.svg" alt="img" className='img-fluid' />
                                                    <h6>Deleting Protocol Keys (Github)</h6>
                                                </div>
                                                <p>Once deleted, it will no longer be possible to change the protocol’s code repositories (not even by the core team), thus completely removing central authority.</p>
                                            </div>
                                        </div> : ""
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="right-side">
                            <h5>The Protocol</h5>
                            <p>The backbone of DOP, enabling secure data sharing with selective transparency.
                            </p>
                            {
                                currentPage === 0 ? <a onClick={switchToPage1} className="btn-view">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                        <path d="M7.79175 6.16671L4.95841 9.00004L7.79175 11.8334M12.0417 6.16671L9.20841 9.00004L12.0417 11.8334" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <h6>View</h6>
                                </a> : <a onClick={() => setCurrentPage(0)} className="btn-view">
                                    <h6 style={{ borderRight: "1px solid #FFF" }}>Back</h6>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                        <path d="M9.2085 6.16668L12.0418 9.00001L9.2085 11.8333M4.9585 6.16668L7.79183 9.00001L4.9585 11.8333" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </a>
                            }

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Protocol
